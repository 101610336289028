// Colors
$dark-grey: #212121;
$yellow: #FFC107;
$off-white: #f7f7f7;
//Font size
$font-size-large: 1.8rem;
$font-size-small: 1.4rem;
// Spacing
$s-size: 1.2rem;
$m-size: 1.6rem;
$l-size: 3.2rem;
$xl-size: 4.8rem;
$desktop-breakpoint: 45rem;