.projects-container {
    display: flex;
    width: 100vw;
    height: 100vh;
    flex-direction: column;
        position: sticky;
        top: 0px;
        background-color: white;
        box-shadow: 0 -5px 5px -5px #333;
        z-index: 7;
}

.main-content {
    margin-top: auto;
    margin-bottom: 5rem;
    width: 40%;
    .description {
        font-weight: bold;
        font-family: 'Bebas Neue', cursive;
    }
}

.project-content-container {
    display: flex;
    height: 100%;
    padding: 12rem 5rem 0 10rem;
}

@media (max-width: 450px) {
    .projects-container {
        .pagination-container {
            margin-top: -120%;
        }
    }
    .project-content-container {
        padding: 0;
        flex-direction: column;
    }
    .main-content {
        width: 100%;
        padding: 2rem;
    }
}

@media (min-width: 990px) {
    .main-content {
        .intro {
            margin: 40px 0;
        }
        .description {
            font-size: 2.5rem;
        }
    }
}