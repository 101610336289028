.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  padding: 0 10rem;
    z-index: 7;
    background: linear-gradient(90deg, #212121 50%, white 50%);
    width: 100%;
}

.logo {
  height: 100px;
  transition: height 0.5s ease;
}

.nav-scroll {
  position: fixed;
  display: flex;
  padding: 0 10rem;
  background-color: white;
  width: 100%;
  z-index: 10;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  .logo {
    height: 50px;
  }
  .bm-burger-button {
    top: 10px;
  }
  // .bm-burger-bars {
  //   background: #fff;
  // }
  transition: all 0.7s ease;
}




/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    right: 10rem;
    top: 36px;
    transition: top 0.5s ease;
}

.bm-burger-bars {
    height: 14% !important;
}
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: $dark-grey;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: $dark-grey;
    opacity: 1 !important;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: $dark-grey;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: $off-white;
    text-decoration: none;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

@media (max-width: 450px) {
  .navbar {
    padding: 0;
  }
  .bm-burger-button {
    right: 2rem;
  }
  .nav-scroll {
    padding: 0 2rem;
  }
}