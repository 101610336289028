.header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    background: linear-gradient(90deg, $dark-grey 50%, white 50%);
    position: sticky;
}

.type {
    color: #fff;
    font-size: 25rem;
}
.type-black {
    color: black;
    font-size: 25rem;
}

.social-icons {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 1%;
    .social-images {
        width: 50px;
    }
}

.heroText-desktop {
    display: flex;
}
.heroText-mobile {
    display: none;
}

@media (max-width: 990px) {
    .type {
        font-size: 15rem;
    }
    .type-black {
        font-size: 15rem;
    }
}

@media (max-width: 450px) {
    .type {
        font-size: 5rem;
    }
    .type-black {
        font-size: 5rem;
    }
    .social-images {
        width: 30px !important;
    }
    .heroText-desktop {
        display: none;
    }
    .heroText-mobile {
        display: flex;
    }
}

@media (min-width: 991px) {
    .header {
        top: 50px;
    }
}