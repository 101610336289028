.about-section {
    display: flex;
    height: 100vh;
        z-index: 5;
        position: sticky;
        box-shadow: 0 -5px 5px -5px #333;
        background-color: white;
}

.about-left {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 50%;
}
.header-text-blinking-mobile {
    display: none;
}

@media (max-width: 450px) {
    .about-section {
        flex-direction: column;
    }
    .about-left {
        width: 100%;
    }
    .header-text-blinking {
        display: none;
    }
    .header-text-blinking-mobile {
        display: flex;
        flex-direction: column;
        //align-items: center;
        justify-content: center;
        font-size: 4rem;
        width: 100%;
        .lower-text {
            margin-top: -40% !important;
            margin-bottom: 0px;
        }
    }
    .about-right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 !important;
        width: 100% !important;
        h2 {
            padding: 0 4rem;
        }
    }
    .services-list {
        padding: 0 4rem;
    }
    .blinking-cursor {
        font-size: 9rem;
    }
    .about-right-desktop {
        display: none;
    }
    .about-right-mobile {
        display: flex !important;
    }
    .divider-mobile {
        height: 100px;
        background: white;
        position: relative;
    }
    .divider-mobile2 {
        height: 150px;
        background: white;
        position: relative;
    }
}
.about-right-mobile {
    display: none;
}
.about-right-mobile2 {
    display: none;
}

//css for Safari
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0)
{ @media (max-width:500px){
    .divider-mobile {
        height: 100px;
        background: white;
        position: relative;
        margin-top: 100px;
    }
}}

.about-right {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    height: 100%;
    // width: 50%;
    // padding: 10rem 12rem 0;
    h2 {
        margin: 0px;
    }
}

.about-type-black {
    color: black;
    font-weight: bold;
    font-size: 12rem;
}

.about-yellow-header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
    font-weight: bold;
    font-family: 'Bebas Neue', cursive;
    color: white;
    background-color: $yellow;
    padding: 0.2rem;
}

.services-list {
    font-family: 'Inter', sans-serif;
}

.about-blinkingText-yellow-character {
    color: $yellow;
}

@media (min-width: 990px) {
    .about-section {
        top: 0px;
    }
}