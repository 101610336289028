.repos-container {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-grow: 1;
    // height: 80%;
    // margin-top: auto;
}
.repository-card {
    width: 45%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    padding: 2rem;
    margin: 1rem;
    height: 30vh;
    overflow: hidden;
    &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    }
    h3 {
        margin-top: 0;
    }
    a {
        text-decoration: none;
        color: $dark-grey;
    }
    .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.intro {
    a {
        text-decoration: none;
        color: #1976D2;
    }
}

@media (max-width: 450px) {
    .repos-container {
        width: 100%;
    }
    .repository-card {
        width: 100%;
    }
}