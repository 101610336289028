.portfolio-container {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction:column;
        z-index: 6;
        position: sticky;
        background-color: white;
        box-shadow: 0 -5px 5px -5px #333;
}

.portfolio-description {
    padding: 10rem 0px 0px 10rem;
}

.header-text-blinking {
    padding: 10rem 0px 0px 10rem;
    width: 40%;
    position: absolute;
    font-size: 5rem;
    h1 {
        margin: 0px;
    }
    .lower-text {
        margin-top: -20%;
    }
}


.blinking-cursor {
    font-weight: 100;
    font-size: 11rem;
    color: #2E3D48;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
  }
  
  @keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-moz-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-webkit-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-ms-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }
  
  @-o-keyframes blink {
    from, to {
      color: transparent;
    }
    50% {
      color: black;
    }
  }


.client-details-container {
    display: flex;
    padding: 15rem 5rem 0 10rem;
    height: 100%;
}
.client-container {
    display: flex;
}
.client-description-container {
    display: flex;
    flex-direction: column;
    margin-top: auto; 
}
.intro {
    font-weight: bold;
    font-size: 2rem;
    span {
        font-family: 'Bebas Neue', cursive;
        letter-spacing: 0.2em;
    }
}
.description {
    font-family: 'Inter', sans-serif;
    background-color: $yellow;
}
.client-display-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 50%;
    a {
      max-height: 400px;
        display: flex;
        img {
            max-width: 500px;
            margin: auto;
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
        }
    }
    .technology-description {
        font-family: 'Bebas Neue', cursive;
        font-weight: bold;
        letter-spacing: 0.1em;
    }
}

.pagination-container {
    margin-top: auto;
}
.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    li {
        padding: 1.2rem;
        .page-link {
            text-decoration: none;
            color: $dark-grey;
            &.active {
                font-weight: bold;
                font-size: 2.2rem;
                transition: all 0.5s ease;
            }
        }
    }
}


@media (max-width: 450px) {
    .client-details-container {
        padding: 0px;
    }
    .client-container {
        flex-direction: column;
    }
    .client-description-container {
        padding: 0 3rem;
    }
    .pagination-container {
        //margin-top: 50px;
        margin-bottom: -30%;
        .pagination {
            padding: 0;
        }
    }
    .client-display-container {
      width: 100vw;
      margin-top: auto;
      a {
        max-height: 200px;
        img {
          //width: 80vw;
          display: none;
        }
      }
    }
}

a {
  color: black;
  text-decoration: none;
}

@media (min-width: 990px) {
  .portfolio-container {
    top: 0px;
  }
    .client-description-container {
        .intro {
            margin: 40px 0;
        }
    }
    .client-display-container {
        padding-left: 10rem;
    }
}