@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css?family=Inter&display=swap');

* {
    box-sizing: border-box;
  }
  
  html {
    font-size: 62.5%;
    font-family: 'Roboto', sans-serif;
  }
  
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $dark-grey;
    font-size: $m-size;
    line-height: 1.6;
  }